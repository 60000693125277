<template>
    <!-- 彈窗 -->
    <Dialog
        :showDialog="showDialog"
        :customClass="'flex justify-center flex-1'"
        :clickOutsideClose="false"
        @onCloseDialog="showDialog = false"
    >
        <div class="relative text-center bg-white rounded-lg md:p-10">
            <div
                class="absolute top-1 right-4 close"
                @click="showDialog = false"
            >
                <i class="fas fa-times"></i>
            </div>
            <div class="content">
                <div class="title">變更訂單狀態</div>
                <div class="status">
                    目前訂單狀態：
                    <span class="text-orange"> {{ status | dateStatus }} </span>
                </div>
            </div>

            <div class="details">
                <div class="detail">
                    <div class="label">合計</div>
                    <div class="value">
                        $ {{ info.totalCount | formatCurrency }}
                    </div>
                </div>
                <div class="detail">
                    <div class="label">平台服務費</div>
                    <div class="value">
                        $ {{ info.serviceCharge | formatCurrency }}
                    </div>
                </div>
                <div class="detail">
                    <div class="label">訂單總額</div>
                    <div class="value">
                        $ {{ info.totalPrice | formatCurrency }}
                    </div>
                </div>
                <div class="detail">
                    <div class="label">快閃折抵金</div>
                    <div class="value">
                        $ {{ info.voucherUsed | formatCurrency }}
                    </div>
                </div>
                <div class="detail">
                    <div class="label">會員實付儲值金</div>
                    <div class="value text-orange">
                        $
                        {{
                            (info.memberPaid - info.voucherUsed)
                                | formatCurrency
                        }}
                    </div>
                </div>
            </div>

            <div class="inputs">
                <div class="input">
                    <div class="label whitespace-nowrap">變更狀態</div>
                    <el-select
                        v-model="inputs.status"
                        size="small"
                        class="w-[300px]"
                    >
                        <el-option
                            v-for="(status, index) in payStatusOptions"
                            :key="index"
                            :label="status.label"
                            :value="status.value"
                        >
                            {{ status.label }}
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="inputs">
                <div v-if="showAmount && order.paid_by !== 1" class="input">
                    <div class="label whitespace-nowrap">修正訂單合計</div>
                    <el-input
                        v-model="inputs.fixTotal"
                        class="w-[300px]"
                        size="small"
                        type="number"
                    >
                    </el-input>
                </div>
            </div>
            <div class="inputs">
                <div v-if="showAmount && order.paid_by !== 1" class="input">
                    <div class="label whitespace-nowrap">修正服務費</div>
                    <el-input
                        v-model="inputs.fixService"
                        class="w-[300px]"
                        size="small"
                        type="number"
                    >
                    </el-input>
                </div>
            </div>
            <div class="inputs">
                <div v-if="showAmount && order.paid_by !== 1" class="input">
                    <div class="label whitespace-nowrap">修正折抵金</div>
                    <el-input
                        v-model="inputs.fixVoucher"
                        class="w-[300px]"
                        size="small"
                        type="number"
                    >
                    </el-input>
                </div>
            </div>
            <div class="inputs">
                <div class="input">
                    <div class="label whitespace-nowrap">客訴單編號</div>
                    <el-select
                        v-model="inputs.feedback_id"
                        class="w-[300px]"
                        size="small"
                        placeholder="請選擇客訴單編號"
                    >
                        <el-option
                            v-for="(status, index) in userFeedbackOptions"
                            :key="index"
                            :label="status.label"
                            :value="status.value"
                        >
                            {{ status.label }}
                        </el-option>
                    </el-select>
                    <!-- <el-input v-model="inputs.feedback_id" size="small"> </el-input> -->
                </div>
            </div>
            <div v-if="showNote" class="inputs">
                <div class="input">
                    <div class="label whitespace-nowrap">變更原因</div>
                    <el-input
                        v-model="inputs.note"
                        class="w-[300px]"
                        size="small"
                        type="textarea"
                    >
                    </el-input>
                </div>
            </div>
            <div class="err-msg">{{ errorMessage }}</div>
            <div class="flex justify-center pt-2 mt-3 border-gray-100">
                <el-button
                    class="orange-btn-800 md:text-sm text-xs h-[30px]"
                    :disabled="confirmDisabled"
                    :loading="loading"
                    @click="confirm"
                >
                    確認變更
                </el-button>
            </div>
        </div>
    </Dialog>
</template>

<script>
import Dialog from "@/components/Dialog.vue";
// 訂單狀態
import orderConfig from "@/config/orderConfig";
import { handleErrorMessage } from "@/service/errorMessage";
export default {
    name: "PopUpUpdateOrder",
    components: { Dialog },
    props: {
        id: {
            type: [Number, String],
            require: true,
        },
        value: {
            type: Boolean,
            require: true,
        },
        // 訂單狀態
        status: {
            type: Number,
            require: true,
        },
        // 款項資訊
        info: {
            type: Object,
            require: true,
            default() {
                return {
                    totalCount: 0, // 訂單合計
                    serviceCharge: 0, // 服務費合計
                    totalPrice: 0, // 訂單總額
                    memberPaid: 0, // 會員實際已付
                    showPaid: 0, //畫面顯示實際已付
                    showProviderRemuneration: 0, // 服務商預期收益
                };
            },
        },
        // 客訴單選項
        userFeedbackOptions: {
            type: Array,
            require: true,
            default: () => [],
        },
        // 訂單資料
        order: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    computed: {
        showDialog: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("input", val);
            },
        },
    },
    data() {
        return {
            // 輸入
            inputs: {
                status: null,
                feedback_id: "",
                note: "",
            },
            errorMessage: "",
            confirmDisabled: true,
            showAmount: false,
            showNote: false,
            loading: false,
            // 活動選項 options
            payStatusOptions: [
                {
                    label: "會員臨時取消",
                    value: -3,
                },
                {
                    label: "服務商取消",
                    value: -1,
                },
                {
                    label: "爭議處理中",
                    value: -4,
                },
                {
                    label: "已完成（服務商可回款）",
                    value: 4,
                },
                {
                    label: "已完成（原爭議處理完成）",
                    value: 6,
                },
            ],
        };
    },
    methods: {
        // 控制確認 Disabled
        checkDataFill(val) {
            const {
                status,
                note,
                feedback_id,
                fixTotal,
                fixService,
                fixVoucher,
            } = val;
            this.confirmDisabled = true;
            if (status && feedback_id) {
                // 檢查原因欄位是否必填
                if (note) {
                    this.confirmDisabled = false;
                } else {
                    if (this.showNote === false) {
                        this.confirmDisabled = false;
                        return;
                    }
                    this.confirmDisabled = true;
                    return;
                }
                this.confirmDisabled = false;
            } else {
                this.confirmDisabled = true;
            }

            // 爭議處理完成且非現金單時，金額為必填
            if (
                this.order.paid_by !== 1 &&
                status === 6 &&
                (!fixTotal || !fixService || !fixVoucher)
            ) {
                this.confirmDisabled = true;
            }
        },
        confirm() {
            const request = { ...this.inputs };

            if (!request.fixTotal) {
                request.fixTotal = null;
            }

            if (!request.fixService) {
                request.fixService = null;
            }

            if (!request.fixVoucher) {
                request.fixVoucher = null;
            }

            if (!request.feedback_id) {
                delete request.feedback_id;
            }

            if (!request.note) {
                delete request.note;
            }

            if (request.status === 2) {
                this.changeNowActivityOrderStatus();
            } else if (request.status === 6) {
                this.checkDatingDryrun(request);
            } else {
                this.update(request);
            }
        },
        update(request) {
            this.loading = true;
            this.$api
                .UpdateDating(this.id, request)
                .then((res) => {
                    this.$emit("update", res.data.dating);
                    this.$message({
                        type: "success",
                        message: "更新成功",
                    });
                })
                .catch((err) => {
                    this.errorMessage = handleErrorMessage(
                        err.response.data.errors
                    );
                    this.$message({
                        type: "error",
                        message: "更新失敗",
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        // 顯示需確認彈窗，並打api檢查是否通過
        checkDatingDryrun(data) {
            const request = {
                status: this.inputs.status,
                price: this.inputs.fixTotal,
                fee: this.inputs.fixService,
                feedback_id: this.inputs.feedback_id,
                voucher: this.inputs.fixVoucher,
            };
            this.$api.CheckDatingDryrun(this.id, request).then((res) => {
                const opts = {
                    title: "確認變更訂單",
                    disabled: res.data.result === "failed",
                    message: `
                    訂單狀態：${orderConfig.orderStatus[data.status]}
                    <br> 訂單合計：$ ${data.fixTotal ?? this.info.totalCount}
                    <br> 平台服務費：$ ${
                        data.fixService ?? this.info.serviceCharge
                    }
                    <br> 折抵金：$ ${data.fixVoucher ?? this.info.voucherUsed}
                    <br> 會員實付儲值金： ${
                        res.data.result === "failed"
                            ? `<span style="color:red;">$ ${res.data.new_paid}</span>`
                            : ` <span>$ ${res.data.new_paid}</span>`
                    }
                    <br> 訂單總額：$ ${
                        parseInt(data.fixTotal ?? this.info.totalCount) +
                        parseInt(data.fixService ?? this.info.serviceCharge)
                    } <br>
                    <br> ${
                        res.data.result === "failed"
                            ? "<span style='color:red'>請確認『修改後會員實付儲值金』不得大於『原會員實付儲值金』</span>"
                            : ""
                    }
                `,
                };
                this.$pop.popConfirm(opts).then(
                    () => {
                        this.update(request);
                    },
                    () => {
                        return;
                    }
                );
            });
        },
        /**
         * 將動態即刻快閃單改頹 status2 且 started_at 為 null
         */
        async changeNowActivityOrderStatus() {
            const request = {
                status: this.inputs.status,
                feedback_id: this.inputs.feedback_id,
            };

            try {
                await this.$api.ChangeNowActivityOrderStatus3ToStatus2Api(
                    this.id,
                    request
                );

                this.$message({
                    type: "success",
                    message: "變更為服務商未到場狀態成功",
                });
                this.$emit("update");
            } catch (err) {
                this.$message({
                    type: "error",
                    message: "變更為服務商未到場狀態失敗",
                });
            }
        },
    },
    watch: {
        inputs: {
            deep: true,
            handler(val) {
                this.errorMessage = "";
                const { status, note, fixTotal, fixService, fixVoucher } = val;
                // 必填欄位檢查
                this.confirmDisabled = status && note;
                // 畫面顯示控制
                this.showNote = this.inputs.status !== -4;
                this.showAmount =
                    this.inputs.status === 6 || this.inputs.status === 4;
                if (!this.showNote) {
                    this.inputs.note = "";
                }
                // 爭議處理完成
                if (status === 6) {
                    this.errorMessage = "";
                    if (this.info.totalCount - fixTotal < 0) {
                        this.errorMessage = "修正訂單合計不得大於實際已付";
                        this.confirmDisabled = true;
                        return;
                    }
                    if (this.info.serviceCharge - fixService < 0) {
                        this.errorMessage = "修正服務費不得大於平台服務費";
                        this.confirmDisabled = true;
                        return;
                    }
                    if (this.info.voucherUsed - fixVoucher < 0) {
                        this.errorMessage = "修正折抵金不得大於快閃折抵金";
                        this.confirmDisabled = true;
                        return;
                    }
                }
                // 確認資料是否完整
                this.checkDataFill(val);
            },
        },
    },
    created() {
        if (this.order.details) {
            /**
             * 判斷是否為動態即刻快閃單  動態即刻快閃單 一定會有 travelTime > 0
             * 判斷狀態是否為 status3
             */
            if (this.order.details.travelTime > 0 && this.order.status === 3) {
                this.payStatusOptions.push({
                    label: "服務商未到場（回到已確認狀態）",
                    value: 2,
                });
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.pop-width {
    width: 800px;
    margin: 0;
}
.content {
    .status {
        font-size: 14px;
        font-weight: 500;
        margin-top: 10px;
        color: #707070;
    }
}

.details {
    border-radius: 10px;
    border: 1px solid #d1d1d1;
    margin: 10px;
    padding: 5px;
    .detail {
        display: flex;
        justify-content: space-between;
        padding: 5px 15px;
        .label {
            font-weight: 500;
            font-size: 14px;
            color: #707070;
        }
        .value {
            font-weight: 500;
            font-size: 14px;
        }
    }
}

.inputs {
    .input {
        display: flex;
        align-items: center;
        padding: 10px 15px;
        .label {
            width: 150px;
            font-size: 14px;
            text-align: center;
        }
    }
}

.text-orange {
    color: #ff5733;
    font-size: 14px;
}

.err-msg {
    width: 100%;
    text-align: center;
    color: red;
    font-size: 14px;
}
</style>
