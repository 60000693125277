<template>
    <div>
        <LoadingComponent :isLoading="loading" />
        <!-- collapse  -->
        <el-collapse>
            <el-collapse-item>
                <template slot="title">
                    <div class="items-center px-4 collapse-title">
                        <div class="title">
                            訂單狀態 : {{ info.status | dateStatus }}
                            <i class="ml-2 fas fa-chevron-down"></i>
                        </div>
                        <button
                            v-permission="['create']"
                            class="orange-btn-800 md:text-sm text-xs h-[30px]"
                            @click.stop="showCustomerComplaint = true"
                        >
                            建立客訴
                        </button>
                    </div>
                </template>
                <div
                    v-for="log in logs.slice().reverse()"
                    :key="log.created_at"
                    class="collapse-item"
                >
                    <span>{{ log.status | dateStatus }} ：</span>
                    {{ log.created_at | formatDateTime }} ｜
                    <span v-if="log.administrator">{{
                        log.administrator.name
                    }}</span>
                    <span v-else-if="log.user">{{ log.user.name }}</span>
                </div>
            </el-collapse-item>
        </el-collapse>
        <el-collapse
            v-if="!$isEmpty(info.paid_by_cash_status)"
            v-model="cashOrderStatusLogActive"
            @change="changeCashOrderStatusActive"
        >
            <el-collapse-item :nmae="'paid_by_cash_status'">
                <template slot="title">
                    <div class="items-center px-4 collapse-title">
                        <div class="flex items-center title">
                            現金單狀態&nbsp;:&nbsp;
                            <strong class="text-red-500">{{
                                cashOrderByProviderEscheatStatus[
                                    info.paid_by_cash_status.status
                                ]
                            }}</strong>

                            <div
                                :class="
                                    cashOrderStatusLogActive.length > 0
                                        ? '-rotate-90 duration-500'
                                        : ''
                                "
                                :style="
                                    cashOrderStatusLogActive.length > 0
                                        ? 'margin-top: 8px;'
                                        : ''
                                "
                            >
                                <i
                                    class="ml-2 transform fas fa-chevron-down"
                                ></i>
                            </div>
                        </div>
                    </div>
                </template>
                <div
                    v-for="(log, key) in info.paid_by_cash_status.logs"
                    :key="key"
                    class="collapse-item"
                >
                    <span v-if="key === 'settled'"
                        >{{ cashOrderByProviderEscheatLogStatus[key] }} ：</span
                    >
                    <span v-if="key === 'settled'">{{
                        log.time | formatDateTime
                    }}</span>
                    <span v-if="key === 'paid'"
                        >{{ cashOrderByProviderEscheatLogStatus[key] }} ：</span
                    >
                    <span v-if="key === 'paid'">{{
                        log.time | formatDateTime
                    }}</span>
                </div>
            </el-collapse-item>
        </el-collapse>
        <!-- 新增客訴單 -->
        <div
            v-if="showCustomerComplaint"
            class="mt-4 mb-0 bg-white rounded-lg detail-info"
        >
            <div class="section-content">
                <div class="salary-info">
                    <div class="section-title" style="width: 90%">新增客訴</div>
                    <div class="inputs">
                        <div class="input">
                            <div class="label">問題分類 *</div>
                            <el-select
                                v-model="inputs.type"
                                size="small"
                                class="w-full"
                                placeholder="選擇問題分類"
                                clearable
                            >
                                <el-option
                                    v-for="(type, index) in problemOptions"
                                    :key="index"
                                    :label="type.label"
                                    :value="type.value"
                                >
                                    {{ type.label }}
                                </el-option>
                            </el-select>
                        </div>
                        <div class="input">
                            <div class="label">發起人 手機 *</div>
                            <el-input
                                v-model="inputs.phone"
                                placeholder="請輸入發起人 手機"
                                size="small"
                                @change="changePhone"
                            >
                            </el-input>
                            <div v-if="showPhoneError" class="error">
                                手機格式錯誤
                            </div>
                            <el-radio v-model="radio" label="1"
                                >會員手機</el-radio
                            >
                            <el-radio v-model="radio" label="2"
                                >服務商手機</el-radio
                            >
                        </div>
                        <div class="input">
                            <div class="label">緊急程度 *</div>
                            <el-select
                                v-model="inputs.severity"
                                size="small"
                                class="w-full"
                                placeholder="請選擇緊急程度"
                                clearable
                            >
                                <el-option
                                    v-for="(severity, index) in severityOptions"
                                    :key="index"
                                    :label="severity.label"
                                    :value="severity.value"
                                >
                                    {{ severity.label }}
                                </el-option>
                            </el-select>
                        </div>
                        <div class="input">
                            <div class="label">客訴對象 手機</div>
                            <el-input
                                v-model="inputs.defendant_phone"
                                placeholder="請輸入客訴對象 手機"
                                size="small"
                                @change="changePhone2"
                            >
                            </el-input>
                            <div v-if="showPhoneError2" class="error">
                                手機格式錯誤
                            </div>
                        </div>
                        <div
                            v-if="inputs.type == 3 || inputs.type == 7"
                            class="input"
                        >
                            <div class="label">客訴訂單編號*</div>
                            <el-input
                                v-model="inputs.order_id"
                                disabled
                                size="small"
                            ></el-input>
                        </div>
                        <div class="textarea">
                            <div class="label">問題描述 *</div>
                            <el-input
                                v-model="inputs.feedback"
                                placeholder="請輸入問題內容"
                                type="textarea"
                            >
                            </el-input>
                        </div>
                    </div>
                    <!-- 上傳圖片區塊 -->
                    <el-upload
                        v-if="images.length < 5"
                        action="#"
                        :limit="10"
                        multiple
                        :auto-upload="false"
                        :on-change="changeFile"
                        accept=".jpg, .jpeg, .png"
                        list-type="none"
                        :file-list="fileList"
                    >
                        <button
                            class="orange-btn-outline-800 text-xs w-[100px] h-[26px] flex-1 mb-[2px]"
                        >
                            上傳截圖
                        </button>
                        <div slot="file" class="flex"></div>
                    </el-upload>
                    <div class="images">
                        <div
                            v-for="(i, idx) in images"
                            :key="idx"
                            class="image"
                            :style="{
                                background: `url('${i.base64}') no-repeat center`,
                            }"
                        >
                            <div class="trash" @click="deleteIMG(idx)">
                                <i class="far fa-trash-alt"></i>
                            </div>
                        </div>
                    </div>
                    <div class="flex justify-center pt-2 mt-3 border-gray-100">
                        <el-button
                            class="black-btn-outline md:text-sm text-xs h-[40px] w-[150px]"
                            @click="showCustomerComplaint = false"
                        >
                            取消
                        </el-button>
                        <el-button
                            class="orange-btn-800 md:text-sm text-xs h-[40px] w-[150px]"
                            :disabled="disabled"
                            @click="addComplaint"
                        >
                            新增
                        </el-button>
                    </div>
                </div>
            </div>
        </div>
        <!-- 客訴單 -->
        <div
            v-if="info.user_feedbacks.length > 0"
            class="mt-4 mb-0 bg-white rounded-lg detail-info"
        >
            <div class="section-content">
                <div class="section-title" style="width: 90%">客訴單</div>
                <div class="section-table">
                    <div class="table-tr">
                        <td class="flex-1">編號</td>
                        <td class="flex-1">狀態</td>
                        <td class="flex-1">問題分類</td>
                        <td class="flex-1">建立人</td>
                        <td class="flex-1">建立時間</td>
                    </div>
                    <div
                        v-for="i in info.user_feedbacks"
                        :key="i.id"
                        class="table-tr"
                    >
                        <td class="flex-1">
                            {{ i.id }}
                            <span
                                class="ml-2 cursor-pointer icon"
                                @click="copy(i.id)"
                            >
                                <i class="far fa-clone"></i>
                            </span>
                        </td>
                        <td class="flex-1">
                            {{ i.status | complaintStatus }}
                        </td>
                        <td class="flex-1">{{ i.type | complaintType }}</td>
                        <td class="flex-1">
                            {{ i.administrator ? i.administrator.name : "" }}
                        </td>
                        <td class="flex-1">
                            {{ i.created_at | formatDateTime }}
                        </td>
                    </div>
                </div>
            </div>
        </div>
        <!-- 資訊 -->
        <div class="mt-4 bg-white rounded-lg detail-info">
            <div class="salary-info">
                <!-- 會員資訊 -->
                <div class="section-content">
                    <div v-if="info.user" class="left">
                        <div class="section-title">會員</div>
                        <div class="avatar">
                            <img class="circle" :src="info.user.avatar" />
                            <div
                                class="name"
                                @click="goProfile(false, info.user.id)"
                            >
                                {{ info.user.name }}
                            </div>
                            <div
                                class="message"
                                @click="
                                    toMessage('members', info.user.banana_id)
                                "
                            >
                                <i class="fas fa-comment-alt"></i>
                            </div>
                        </div>
                        <div class="line-item">
                            <div class="label">手機</div>
                            <div class="text">+{{ info.user.phone }}</div>
                            <div class="icon" @click="copy(info.user.phone)">
                                <i class="far fa-clone"></i>
                            </div>
                        </div>
                        <div class="line-item">
                            <div class="label">電子郵件</div>
                            <div class="text">
                                {{ info.user.email }}
                            </div>
                            <div class="icon">
                                <!-- <i class="far fa-clone"></i> -->
                            </div>
                        </div>
                    </div>
                    <div v-if="info.provider" class="right">
                        <div class="section-title">服務商</div>
                        <div class="avatar">
                            <img class="circle" :src="info.provider.avatar" />
                            <div
                                class="name"
                                @click="goProfile(true, info.provider.id)"
                            >
                                {{ info.provider.name }}
                            </div>
                            <div
                                class="message"
                                @click="
                                    toMessage(
                                        'providers',
                                        info.provider.banana_id
                                    )
                                "
                            >
                                <i class="fas fa-comment-alt"></i>
                            </div>
                        </div>
                        <div class="line-item">
                            <div class="label">手機</div>
                            <div class="text">+{{ info.provider.phone }}</div>
                            <div
                                class="icon"
                                @click="copy(info.provider.phone)"
                            >
                                <i class="far fa-clone"></i>
                            </div>
                        </div>
                        <div class="line-item">
                            <div class="label">電子郵件</div>
                            <div class="text">
                                {{ info.provider.email }}
                            </div>
                            <div class="icon">
                                <!-- <i class="far fa-clone"></i> -->
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 訂單資訊 -->
                <div class="section-content">
                    <div class="section-title" style="width: 90%">訂單資訊</div>
                    <div class="left">
                        <div class="line-item">
                            <div class="label">訂單編號</div>
                            <div class="flex-1 text">
                                {{ info.order_id }}
                            </div>
                        </div>
                        <div class="line-item">
                            <div class="label">發起來源</div>
                            <div class="flex-1 text">
                                {{ info.source_application }}
                            </div>
                        </div>
                        <div class="line-item">
                            <div class="label">活動屬性</div>
                            <div class="flex-1 text">
                                {{ info.is_x ? "CitybananaX" : "Citybanana" }}
                            </div>
                        </div>
                        <div class="line-item">
                            <div class="label">活動項目</div>
                            <div class="flex-1 text">
                                {{ info.category.name }}
                            </div>
                        </div>
                        <div class="line-item">
                            <div class="label">會面城市</div>
                            <div class="flex-1 text">
                                {{
                                    info.district
                                        ? areas[info.district].name
                                        : ""
                                }}
                            </div>
                        </div>
                        <div class="line-item">
                            <div class="label">會面地點</div>
                            <div class="flex-1 text">{{ info.location }}</div>
                        </div>
                        <div v-if="info.requirement" class="line-item">
                            <div class="label">其他需求備註</div>
                            <div class="flex-1 text">
                                {{ info.requirement }}
                            </div>
                        </div>
                        <div class="line-item">
                            <div class="label">備註</div>
                            <div class="flex-1 text">
                                {{ info.description }}
                            </div>
                        </div>
                    </div>
                    <div class="right">
                        <div class="line-item">
                            <div class="label">開始時間</div>
                            <div class="flex-1 text">
                                {{ info.started_at | formatDateTime }}
                            </div>
                        </div>
                        <div class="line-item">
                            <div class="label">結束時間</div>
                            <div class="flex-1 text">
                                {{ info.ended_at | formatDateTime }}
                            </div>
                        </div>
                        <div class="line-item">
                            <div class="label">訂單成立時間</div>
                            <div class="flex-1 text">
                                {{ info.created_at | formatDateTime }}
                            </div>
                        </div>
                        <div class="line-item">
                            <div class="label">訂單關閉時間</div>
                            <div class="flex-1 text">
                                {{ info.closed_at | formatDateTime }}
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 訂單明細 -->
                <div class="section-content">
                    <div class="section-title" style="width: 90%">
                        訂單合計<span class="text-red-500">{{
                            info.paid_by === 1 ? "(現金單)" : ""
                        }}</span>
                    </div>
                    <div class="section-table">
                        <div class="table-tr">
                            <td>項目</td>
                            <td>出席費</td>
                            <td>
                                {{
                                    info.details.unit === "hour"
                                        ? "時數"
                                        : "天數"
                                }}
                            </td>
                            <td>小費</td>
                            <td>小計</td>
                        </div>
                        <!-- 出席活動 -->
                        <div class="table-tr">
                            <td>出席活動</td>
                            <td>
                                $
                                {{ activityTotal.hourlyPrice | formatCurrency }}
                            </td>
                            <td>{{ activityTotal.duration }}</td>
                            <td>$ {{ activityTotal.tip }}</td>
                            <td>
                                $
                                {{ activityTotal.price | formatCurrency }}
                            </td>
                        </div>
                        <!-- 續約單 -->
                        <div
                            v-if="info.dating_extensions.length > 0"
                            class="table-tr"
                        >
                            <td>續約</td>
                            <td></td>
                            <td>
                                {{
                                    totalExtensionTotal.hours +
                                    totalExtensionTotalNotCheck.hours
                                }}
                            </td>
                            <td>
                                $
                                {{
                                    (totalExtensionTotal.tip +
                                        totalExtensionTotalNotCheck.tip)
                                        | formatCurrency
                                }}
                            </td>
                            <td>
                                $
                                {{
                                    (totalExtensionTotal.price +
                                        totalExtensionTotalNotCheck.price)
                                        | formatCurrency
                                }}
                            </td>
                        </div>
                        <div class="table-tr total">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>合計</td>
                            <td>
                                ${{ feeRelation.totalCount | formatCurrency }}
                                <div v-if="info.details.finalPrice">
                                    （變更後: {{ info.details.finalPrice }})
                                </div>
                            </td>
                        </div>
                        <div class="table-tr">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>平台服務費</td>
                            <td>
                                $
                                {{ feeRelation.serviceCharge | formatCurrency }}
                                <div v-if="info.details.finalFee">
                                    （變更後:
                                    {{
                                        info.details.finalFee | formatCurrency
                                    }})
                                </div>
                            </td>
                        </div>
                        <div class="table-tr total">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>訂單總額</td>
                            <td>
                                <span class="org-link no-line"
                                    >${{
                                        feeRelation.totalPrice | formatCurrency
                                    }}
                                </span>
                                <div v-if="info.details.finalPrice">
                                    （變更後:
                                    {{
                                        (parseInt(info.details.finalPrice) +
                                            parseInt(info.details.finalFee))
                                            | formatCurrency
                                    }})
                                </div>
                            </td>
                        </div>
                        <div
                            v-if="feeRelation.voucherUsed > 0"
                            class="table-tr"
                        >
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>使用快閃折抵金</td>
                            <td>
                                $ {{ feeRelation.voucherUsed | formatCurrency }}
                                <div v-if="info.details.finalVoucherUsed">
                                    （變更後:
                                    {{
                                        info.details.finalVoucherUsed
                                            | formatCurrency
                                    }})
                                </div>
                            </td>
                        </div>
                    </div>
                </div>
                <!-- 額外小費 -->
                <div v-if="info.extra_tip > 0" class="section-content">
                    <div class="section-title" style="width: 90%">
                        額外小費（不列入下方金額計算）
                    </div>
                    <div class="left">
                        <div class="line-item">
                            <div class="label">小費金額</div>
                            <div class="flex-1 text">
                                {{ info.extra_tip }}
                            </div>
                        </div>
                    </div>
                    <div class="right"></div>
                </div>
                <!-- 續約記錄 -->
                <div
                    v-if="info.dating_extensions.length > 0"
                    class="section-content"
                >
                    <div class="section-title" style="width: 90%">續約記錄</div>
                    <div class="section-table">
                        <div class="table-tr">
                            <td>項目</td>
                            <td>狀態</td>
                            <td>時數</td>
                            <td>小費</td>
                            <td>小計</td>
                        </div>
                        <div
                            v-for="item in info.dating_extensions"
                            :key="item.id"
                            class="table-tr"
                        >
                            <td>續約</td>
                            <td>
                                {{ item.status | extensionStatus }}
                            </td>
                            <td>{{ item.details.duration }}</td>
                            <td>$ {{ item.details.tip }}</td>
                            <td>$ {{ item.details.price | formatCurrency }}</td>
                        </div>
                        <div class="table-tr total">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>合計</td>
                            <td>
                                $
                                {{
                                    (totalExtensionTotal.price +
                                        totalExtensionTotalNotCheck.price)
                                        | formatCurrency
                                }}
                            </td>
                        </div>
                    </div>
                </div>
                <!-- 評價紀錄 -->
                <div
                    v-if="info.provider_score > 0 || info.user_score > 0"
                    class="section-content"
                >
                    <div class="section-title" style="width: 90%">評價紀錄</div>
                    <div v-if="info.user" class="left">
                        <div class="line-item">
                            <div class="label">評價服務商</div>
                            <div class="text">
                                <i
                                    v-for="(i, index) in info.provider_score"
                                    :key="'star' + index"
                                    class="fas fa-star"
                                ></i>
                            </div>
                        </div>
                        <div class="line-item">
                            <div class="label">評論</div>
                            <div class="text">
                                <span v-if="info.provider_score > 0">
                                    {{ info.provider_comment }}
                                </span>
                                <span v-else> 尚未評價 </span>
                            </div>
                            <div class="icon">
                                <!-- <i class="far fa-clone"></i> -->
                            </div>
                        </div>
                    </div>
                    <div v-if="info.provider" class="right">
                        <div class="line-item">
                            <div class="label">評價會員</div>
                            <div class="text">
                                <i
                                    v-for="(i, index) in info.user_score"
                                    :key="'star' + index"
                                    class="fas fa-star"
                                ></i>
                            </div>
                        </div>
                        <div class="line-item">
                            <div class="label">評論</div>
                            <div class="text">
                                <span v-if="info.user_score > 0">
                                    {{ info.user_comment }}
                                </span>
                                <span v-else> 尚未評價 </span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 取消原因 -->
                <div v-if="info.details.refusedNote" class="section-content">
                    <div class="section-title" style="width: 90%">取消原因</div>
                    <div class="line-item2">
                        {{ info.details.refusedNote }}
                    </div>
                </div>
                <!-- 取消原因 -->
                <div v-if="info.details.cancelledNote" class="section-content">
                    <div class="section-title" style="width: 90%">取消原因</div>
                    <div class="line-item2">
                        {{ info.details.cancelledNote }}
                    </div>
                </div>
                <!-- 變更原因 -->
                <div v-if="info.details.finalizedNote" class="section-content">
                    <div class="section-title" style="width: 90%">變更原因</div>
                    <div class="line-item2">
                        {{ info.details.finalizedNote }}
                    </div>
                </div>
                <!-- 收益 -->
                <div class="flex mt-5 OpenSansFont">
                    <div
                        v-if="info.user"
                        class="flex-1 border-r border-gray-300 h-[150px] mr-5"
                    >
                        <div class="section-title">會員</div>
                        <div class="flex">
                            <div class="flex flex-1 text-sm">
                                <div class="mr-5 text-gray-400">實付儲值金</div>
                                <div v-if="info.status === -3">
                                    $
                                    {{
                                        info.paid_by === 1
                                            ? 0
                                            : info.paid | formatCurrency
                                    }}
                                </div>
                                <div v-else>
                                    $
                                    {{
                                        info.paid_by === 1
                                            ? 0
                                            : feeRelation.showPaid
                                              | formatCurrency
                                    }}
                                </div>
                            </div>
                            <div class="flex flex-1 text-sm">
                                <div class="mr-5 text-gray-400">實付現金</div>
                                <div v-if="info.status === -3">
                                    $
                                    {{
                                        info.paid_by === 0
                                            ? 0
                                            : info.paid | formatCurrency
                                    }}
                                </div>
                                <div v-else>
                                    $
                                    {{
                                        info.paid_by === 0
                                            ? 0
                                            : feeRelation.showPaid
                                              | formatCurrency
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="flex mt-5 text-sm">
                            <div class="mr-5 text-gray-400">退還會員</div>
                            <div>
                                $
                                {{
                                    info.paid > 0
                                        ? info.refund
                                        : info.paid | formatCurrency
                                }}
                            </div>
                        </div>
                    </div>
                    <div v-if="info.provider" class="flex-1 h-[150px] mr-5">
                        <div class="section-title">服務商</div>
                        <div class="flex text-sm">
                            <div class="mr-5 text-gray-400">預期收益</div>
                            <div>
                                $
                                {{
                                    feeRelation.showProviderRemuneration
                                        | formatCurrency
                                }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="actions">
                <button
                    class="mr-5 black-btn-outline-lg text-md"
                    @click="backList"
                >
                    返回訂單列表
                </button>
                <button
                    v-if="!info.closed_at"
                    v-permission="['update']"
                    class="orange-btn-800-lg text-md"
                    @click="showDialog = true"
                >
                    變更訂單狀態
                </button>
            </div>
        </div>
        <PopUpUpdateOrder
            v-if="showDialog"
            :id="id"
            v-model="showDialog"
            :info="feeRelation"
            :order="info"
            :status="info.status"
            :userFeedbackOptions="userFeedbackOptions"
            @update="update"
        />
    </div>
</template>
<script>
import PopUpUpdateOrder from "../components/PopUpUpdateOrder.vue";
import LoadingComponent from "@/components/Loading.vue";
import { areas } from "@/langs/tw.json";
import "@/global/filters/filterComplaint";
import orderComplaintConfig from "@/config/orderComplaintConfig";
import { dataURLtoBlob } from "@/service/imageTransfer.js";
// import { handleErrorMessage } from "@/service/errorMessage";
import moment from "moment";
import orderConfig from "@/config/orderConfig";
const inputsDefault = {
    phone: "", // 發起人手機
    defendant_phone: "", // 客訴對象 手機
    type: "",
    severity: "",
    feedback: "",
    order_id: "",
};
export default {
    name: "OrderDetail",
    components: {
        LoadingComponent,
        PopUpUpdateOrder,
    },
    computed: {
        // 判斷是否為服務商
        isProvider() {
            return true;
        },
        // 費用相關
        feeRelation() {
            const totalCount =
                this.activityTotal.price +
                this.totalExtensionTotal.price +
                this.totalExtensionTotalNotCheck.price;
            const serviceCharge =
                this.activityTotal.fee +
                this.totalExtensionTotal.fee +
                this.totalExtensionTotalNotCheck.fee;
            const voucherUsed = this.activityTotal.voucherUsed;
            // 顯示已付點數金額需加入會員已付金 ＋ 待確認續約單 - 優惠券金額
            const finalPaid = this.info.details.finalPrice
                ? this.info.paid - parseInt(this.info.details.finalVoucherUsed)
                : null;
            const showPaid =
                this.info.paid +
                    this.totalExtensionTotalNotCheck.total -
                    voucherUsed >
                0
                    ? this.info.paid +
                      this.totalExtensionTotalNotCheck.total -
                      voucherUsed
                    : 0;
            return {
                totalCount, // 訂單合計
                serviceCharge, // 平台服務費合計
                voucherUsed, // 優惠卷使用金額
                totalPrice: totalCount + serviceCharge, // 訂單總額
                memberPaid: this.info.paid, // 會員實際已付
                showPaid: finalPaid ? finalPaid : showPaid, //畫面顯示實際已付
                showProviderRemuneration: Math.floor(
                    this.info.provider_remuneration +
                        this.totalExtensionTotalNotCheck.price * 0.7
                ), // 服務商預期收益
            };
        },
        // 出席活動費用相關
        activityTotal() {
            return {
                hourlyPrice: this.info.details.hourlyPrice,
                duration: this.info.details.duration,
                tip: this.info.details.tip ? this.info.details.tip : 0,
                fee: this.info.details.fee,
                voucherUsed: this.info.details.voucherUsed
                    ? this.info.details.voucherUsed
                    : 0,
                price: this.info.details.tip + this.info.details.serviceCharge,
            };
        },
        // 完成續約單統計
        totalExtensionTotal() {
            let price = 0;
            let fee = 0;
            let hours = 0;
            let tip = 0;
            this.info.dating_extensions.forEach((i) => {
                // 只取完成的續約單統計
                if (i.status === 2) {
                    price += i.details.price;
                    fee += i.details.fee;
                    hours += i.details.duration;
                    tip += i.details.tip;
                }
            });
            return { price, fee, hours, tip, total: price + fee };
        },
        // 未確認續約單統計
        totalExtensionTotalNotCheck() {
            let price = 0;
            let fee = 0;
            let hours = 0;
            let tip = 0;
            this.info.dating_extensions.forEach((i) => {
                //  未確認的續約單統計
                if (i.status === 1) {
                    price += i.details.price;
                    fee += i.details.fee;
                    hours += i.details.duration;
                    tip += i.details.tip;
                }
            });
            return { price, fee, hours, tip, total: price + fee };
        },
        // 問題 options
        problemOptions() {
            const status = orderComplaintConfig.complaintType;
            const result = [];
            for (const key in status) {
                result.push({
                    label: status[key],
                    value: key,
                });
            }
            return [
                {
                    label: "預訂相關",
                    value: "3",
                },
                {
                    label: "行程突發事件",
                    value: "7",
                },
            ];
        },
        // 警急程度 options
        severityOptions() {
            const status = orderComplaintConfig.complaintSeverity;
            const result = [];
            for (const key in status) {
                result.push({
                    label: status[key],
                    value: key,
                });
            }
            return result;
        },
        userFeedbackOptions() {
            const result = [];
            this.info.user_feedbacks.forEach((i) => {
                result.push({
                    label: i.id,
                    value: i.id,
                });
            });
            return result;
        },
    },
    data() {
        return {
            id: 0,
            info: {
                // 會員資料
                user: {},
                // 服務商資料
                provider: {},
                // 訂單資訊
                category: {},
                // 訂單合計
                details: {},
                // 續約單
                dating_extensions: [],
                // 客訴單
                user_feedbacks: [],
            },
            logs: [],
            loading: false,
            // 地區
            areas,
            showDialog: false,
            showPhoneError: false,
            showPhoneError2: false,
            // 客訴單相關
            inputs: {
                ...inputsDefault,
            },
            images: [],
            fileList: [],
            disabled: true,
            showCustomerComplaint: false,
            radio: "",
            // 服務商繳款紀錄 狀態
            cashOrderByProviderEscheatLogStatus:
                orderConfig.cashOrderByProviderEscheatLogStatus,
            // 服務商現金單收款與繳款狀態
            cashOrderByProviderEscheatStatus:
                orderConfig.cashOrderByProviderEscheatStatus,
            // collapse 開關值
            cashOrderStatusLogActive: [],
        };
    },
    methods: {
        // 更新畫面資料
        async getData() {
            this.loading = true;
            const { status, data } = await this.$api
                .GetDating(this.$route.params.orderID)
                .then((res) => {
                    return { data: res.data, status: res.status };
                })
                .catch(() => {
                    this.$message({
                        type: "error",
                        message: "取得資料失敗",
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
            if (status === 200) {
                this.info = { ...data };
                this.clearComplaintInputs();
                const { tab, userID, pre } = this.$route.params;
                let crumbList = [];
                if (pre !== "OrderManageList") {
                    crumbList = [
                        {
                            meta: {
                                text: "用戶管理",
                            },
                        },
                        {
                            name: "provider_list",
                            meta: {
                                text:
                                    tab !== "provider_update"
                                        ? "會員管理"
                                        : "服務商管理",
                            },
                            usePath: true,
                            path:
                                tab !== "provider_update"
                                    ? "#/user_list/member_list"
                                    : "#/user_list/provider_list",
                        },
                        {
                            name: tab,
                            meta: {
                                text:
                                    tab !== "provider_update"
                                        ? this.info.user.name
                                        : this.info.provider.name,
                            },
                            usePath: true,
                            path: `#/user_list/${tab}/${userID}/OrderList`,
                        },
                        {
                            name: "order_list",
                            meta: {
                                text: "訂單記錄",
                            },
                            usePath: true,
                            path: `#/user_list/${tab}/${userID}/OrderList`,
                        },
                        {
                            name: "order_detail",
                            meta: {
                                text: "訂單細節",
                            },
                        },
                    ];
                } else {
                    crumbList = [
                        {
                            meta: {
                                text: "訂單管理",
                            },
                        },
                        {
                            name: "order_manage",
                            meta: {
                                text: "訂單列表",
                            },
                            usePath: true,
                            path: "/#/order_manage/",
                        },
                        {
                            name: "order_detail",
                            meta: {
                                text: "訂單細節",
                            },
                        },
                    ];
                }
                this.$store.commit("breadcrumbStore/setCrumbList", crumbList);
            }
        },
        async getLogs() {
            const { status, data } = await this.$api
                .GetOrderLogs(this.id)
                .then((res) => {
                    return { data: res.data.logs, status: res.status };
                })
                .catch(() => {
                    this.$message({
                        type: "error",
                        message: "取得資料失敗",
                    });
                });
            if (status === 200) {
                this.logs = [...data];
            }
        },
        update() {
            this.getData();
            this.getLogs();
            this.showDialog = false;
        },
        // 連結相關
        goProfile(isProvider, id) {
            window.open(
                `/#/user_list/${
                    isProvider ? "provider_update" : "member_update"
                }/${id}`
            );
        },
        /**
         * @param { type String(字串) } tab 判斷預設選中聊天 tab 是服務商列表還是會員列表
         * @param { type String(字串) } id 會員 banana id
         */
        toMessage(tab, id) {
            window.open(`/#/firestore/chats/${tab}/${id}`);
        },
        backList() {
            const { tab, userID, pre } = this.$route.params;
            if (pre !== "OrderManageList") {
                this.$router.push({
                    path: `/user_list/${tab}/${userID}/UserOrderList`,
                });
            } else {
                this.$router.push({
                    name: "order_manage",
                });
            }
        },
        // others
        copy(text) {
            const dummy = document.createElement("input");
            document.body.appendChild(dummy);
            dummy.value = text;
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);
            this.$message({ type: "success", message: "複製成功" });
        },
        area(key) {
            return this.areas[key].name;
        },
        // 客訴單相關
        changeFile(file, fileList) {
            if (file.size / (1024 * 1024) > 5) {
                this.fileList = [];
                alert("檔案大小超出5mb");
                return;
            }
            const reader = new FileReader();
            // 讀取上傳檔案
            reader.readAsDataURL(file.raw);
            // 將上傳檔案轉為base64
            reader.onload = () => {
                const fileStamp = {
                    time: moment().format("HHMMSSSS"),
                    url: file.url,
                    file: file.raw,
                    base64: reader.result,
                };

                if (this.images.length < 5) {
                    this.fileList = fileList.slice(-3);
                    this.images.push(fileStamp);
                } else {
                    alert("檔案張數超出限制");
                    return;
                }
            };
        },
        deleteIMG(idx) {
            this.images.splice(idx, 1);
        },
        clearComplaintInputs() {
            this.inputs = { ...inputsDefault };
            this.inputs.order_id = this.info.order_id;
            this.inputs.type = "3";
            this.images = [];
            this.fileList = [];
        },
        addComplaint() {
            this.loading = true;
            const {
                phone, // 發起人手機
                defendant_phone, // 客訴對象 手機
                type,
                severity,
                feedback,
                order_id,
            } = this.inputs;

            const result = new FormData();
            result.append("phone", phone);
            if (type == 3 || type == 7) {
                result.append("order_id", order_id);
            }

            if (defendant_phone) {
                result.append("defendant_phone", defendant_phone);
            }

            result.append("type", type);
            result.append("severity", severity);
            result.append("feedback", feedback);

            this.images.forEach((i) => {
                if (i.base64) {
                    const blob = dataURLtoBlob(i.base64);
                    result.append("images[]", blob);
                }
            });

            this.$api
                .CreateUserFeedback(result)
                .then(() => {
                    this.$message({
                        type: "success",
                        message: "新增成功",
                    });
                    this.showCustomerComplaint = false;
                    this.clearComplaintInputs();
                    this.getData();
                })
                .catch((err) => {
                    const errMessage = err.response?.data.error.message
                        ? err.response?.data.error.message
                        : "新增失敗";

                    this.$message({
                        type: "error",
                        message: errMessage,
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        changePhone(val) {
            const phoneMatch = /886\d{7,17}$/.test(val);
            this.showPhoneError = false;
            if (phoneMatch === false) {
                this.showPhoneError = true;
            }
        },
        changePhone2(val) {
            const phoneMatch = val ? /886\d{7,17}$/.test(val) : true;
            this.showPhoneError2 = false;
            if (phoneMatch === false) {
                this.showPhoneError2 = true;
            }
        },
        // 開關現金單 繳款 log
        changeCashOrderStatusActive(val) {
            this.cashOrderStatusLogActive = val;
        },
    },
    watch: {
        inputs: {
            deep: true,
            handler(val) {
                const {
                    phone, // 發起人手機
                    type,
                    severity,
                    feedback,
                    order_id,
                    defendant_phone,
                } = val;

                const phoneMatch = /886\d{7,17}$/.test(phone);
                const defendantPhoneMatch = defendant_phone
                    ? /886\d{7,17}$/.test(defendant_phone)
                    : true;

                if (type == 3 || type == 7) {
                    if (
                        phoneMatch &&
                        type &&
                        severity &&
                        feedback &&
                        order_id &&
                        defendantPhoneMatch
                    ) {
                        this.disabled = false;
                        this.showPhoneError = false;
                        this.showPhoneError2 = false;
                    } else {
                        this.disabled = true;
                    }
                } else {
                    if (phoneMatch && type && severity && feedback) {
                        this.showPhoneError = false;
                        this.disabled = false;
                    } else {
                        this.disabled = true;
                    }
                }

                if (
                    phone !== this.info.user.phone &&
                    phone !== this.info.provider.phone
                ) {
                    this.radio = "";
                }
            },
        },
        radio(val) {
            if (val == 1) {
                this.inputs.phone = this.info.user.phone;
                this.inputs.defendant_phone = this.info.provider.phone;
            } else if (val == 2) {
                this.inputs.phone = this.info.provider.phone;
                this.inputs.defendant_phone = this.info.user.phone;
            }

            this.showPhoneError = false;
            this.showPhoneError2 = false;
        },
    },
    mounted() {
        this.id = this.$route.params.orderID;
        this.getData();
        this.getLogs();
    },
};
</script>
<style lang="scss" scoped>
@import "@/scss/_text-style.scss";

.collapse-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.action-bar {
    background: white;
    color: chocolate;
    margin: 10px 0;
    height: 55px;
}

::v-deep .el-collapse-item__arrow {
    display: none;
}

.collapse-item {
    span {
        color: #757575;
    }
}

::v-deep .el-collapse-item__content {
    padding-bottom: 10px;
    padding: 0 20px 10px 20px;
}

.detail-info {
    padding: 30px 8%;
    .section-title {
        width: 80%;
        margin-bottom: 20px;
        @extend .text-black-blod-18;
    }
    .section-content {
        margin-top: 20px;
        overflow: scroll;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        padding-bottom: 30px;
        border-bottom: solid 1px #cbcbcb;
        &:last-child {
            border-bottom: none;
        }

        .left {
            flex: 1;
            border-right: 1px solid #cbcbcb;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .right {
            flex: 1;
            padding-left: 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .avatar {
            display: flex;
            justify-content: flex-start;
            width: 80%;
            align-items: center;
            .circle {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                margin-right: 70px;
            }
            .name {
                color: #ff5733;
                text-decoration-line: underline;
                width: 220px;
                cursor: pointer;
            }
            .message {
                color: #ff5733;
                cursor: pointer;
            }
        }
        .fa-star {
            font-size: 12px;
            margin-left: 0px;
            margin-bottom: 2px;
            color: #ff5733 !important;
        }

        .line-item {
            display: flex;
            justify-content: flex-start;
            width: 80%;
            align-items: flex-start;
            margin-top: 10px;
            .label {
                color: #757575;
                font-size: 14px;
                font-weight: 500;
                margin-right: 10px;
                width: 100px;
            }
            .text {
                width: 200px;
                overflow-wrap: break-word;
            }
            .icon {
                cursor: pointer;
                margin-left: 20px;
            }
        }

        .line-item2 {
            display: flex;
            justify-content: flex-start;
            width: 90%;
            align-items: flex-start;
        }

        .item {
            display: flex;
            margin: 10px 0;
            .section-key {
                @extend .text-gray-medium-14;
                width: 150px;
            }
            .section-value {
                @extend .text-black-regular-14;
            }
        }
        .section-table {
            width: 90%;
            .table-tr {
                width: 100%;
                min-height: 40px;
                display: flex;
                .org-link {
                    color: #ff5733;
                    text-decoration: underline;
                    font-size: 14px;
                    cursor: pointer;
                    &.no-line {
                        text-decoration: none;
                    }
                }
                &:first-child {
                    background: #d1d1d1;
                }
                &.total {
                    border-top: 1px solid #cbcbcb;
                }
                td:not(:first-child) {
                    @extend .text-black-regular-14;
                    padding: 0 20px;
                    flex: 1;
                    line-height: 40px;
                    text-align: left;
                }
                td:nth-child(1) {
                    @extend .text-black-regular-14;
                    padding: 0 20px;
                    width: 40%;
                    line-height: 40px;
                    text-align: left;
                }
            }
        }
    }
    .actions {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 30px;
    }
}

.inputs {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 10px;
    .input {
        width: 48%;
        margin: 8px 0;
    }
    .textarea {
        width: 100%;
        margin: 8px 0;
    }
    .label {
        font-size: 14px;
        color: #757575;
        margin-bottom: 5px;
    }
}

.images {
    width: 100%;
    max-width: 100%;
    overflow: scroll;
    display: flex;
    align-items: center;
    .image {
        display: flex;
        width: 25%;
        min-width: 25%;
        height: 200px;
        margin: 10px 5px;
        background-size: contain !important;
        &:hover {
            cursor: pointer;
            .trash {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                background: rgba(0, 0, 0, 0.2);
                font-size: 20px;
            }
        }
        .trash {
            display: none;
        }
    }
}

.error {
    font-size: 12px;
    color: red;
    margin-top: 2px;
}
</style>
